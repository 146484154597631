<template>
    <div class="fix-wrap">
        <div class="filter-wrap">
            <el-select class="select" v-model="filterJob" @change="setFilterFunc('jid', $event.target.value)">
                <el-option :value="''" :label="'전체'"></el-option>
                <el-option v-for="job in jobCategorys" :key="`job${job.mjc_idx}`" :value="job.mjc_idx" :label="job.mjc_job"></el-option>
            </el-select>
            <div class="input check">
                <input type="text" class="value" placeholder="검색어를 입력하세요" v-model="filterInput" @keypress.enter="setFilterFunc('na', filterInput)"></input>
                <button class="send" @click="setFilterFunc('na', filterInput)">검색</button>
            </div>
        </div>
        <div class="list-wrap">
            <div class="selected">
                <div v-for="(mentor, index) in mentorSelList.list" :key="`mentor${index}`"  class="mentor">
                    <div class="profile">
                        <img :src="returnProfileImage(mentor)">
                    </div>
                    <div class="name">{{mentor.mb_name}}</div>
                    <div class="cancel" @click="clickMentor(mentor)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import memberUtil from "@/components/member/memberUtil"
    import EventBus from "@/utils/event-bus";
    import imageOption from "@/mixins/imageOption";

    const {filterConfig} = memberUtil
    export default {
        name: "MemberFilterDefaultLayout",
        mixins: [imageOption],
        inject: ['setData', 'setFilter', 'setMentorList', 'mentorSelList'],
        props: ['filterData'],
        // props: [],
        data() {
            return {
                filterJob: '',
                filterInput: '',
                jobCategorys: [],
            }
        },
        computed: {
            mentors() {
                return this.mentorSelList.hasOwnProperty('list') ? this.mentorSelList.list : [];
            },
        },
        created() {
            this.getJobCategory();
            this.setterFilterDataInData()
        },
        mounted() {
        },
        methods: {
            getJobCategory() {
                this.$api.$member.getJobCategory().then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1 && res.List.length > 0) {
                        this.jobCategorys = res.List;
                    }
                })
            },
            setterFilterDataInData() {
                let classnames = Object.keys(this.filterData);
                classnames.forEach(classname => {
                    let obj = this.filterData[classname];
                    let type = filterConfig[obj.key].type;
                    if (this.hasOwnProperty(classname)) {
                        // this[classname] =
                        if (type === 'query') {
                            this[classname] = obj.query;
                        } else if (type === 'between') {
                            this[classname] = [obj.start, obj.end];
                        } else if (type === 'match') {
                            this[classname] = obj.value;
                        }

                    }
                })
            },
            setFilterFunc(key, value) {
                let classname = filterConfig[key].classname;
                if (!classname) {
                    return;
                }
                this[classname] = value;
                this.setData(key, value);
                this.setFilter(key, value);
            },
            returnProfileImage(member) {
                return member.hasOwnProperty('Profile') && member.Profile.length > 0 ? `${member.Profile[0].org_url}?${this.setImageOptions(82, 82, 'png')}` : ''
            },
            hasSelMentor(mentor) {
                return this.mentors.findIndex(member => member.mb_no === mentor.mb_no) > -1;
            },
            clickMentor(member) {
                let mentors = this.mentors;
                if(this.hasSelMentor(member)) {
                    let mentorIndex = mentors.findIndex(mentor => mentor.mb_no === member.mb_no);
                    mentors.splice(mentorIndex, 1);
                }else{
                    mentors.push(member);
                }
                this.setMentorList(mentors);
            },
        },

        watch: {},
    }
</script>

<style scoped>

</style>